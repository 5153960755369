import React from 'react';
import { Field } from 'formik';
import { InputProps } from './inputProps';

const Input: React.FC<InputProps> = (props) => {
  const { name, type, error, touched, inputStyle, disabled, required, label } = props;
  return (
    <div className={props.divStyle}>
      <label htmlFor={name}>
        {label}
        {required ? '*' : ''}
      </label>
      <Field
        type={type}
        name={name}
        id={name}
        className={error && touched ? 'input-class-error' : inputStyle}
        disabled={disabled}
        required={required}
      />
      <span className="caption">{props.caption}</span>
    </div>
  );
};

export default Input;
