import React from 'react';
import { Field } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import { InputProps } from './inputProps';

const Textarea: React.FC<InputProps> = (props) => {
  const { name, error, touched, inputStyle, disabled, required, label } = props;
  return (
    <div className={props.divStyle}>
      <label htmlFor={name}>
        {ReactHtmlParser(label ?? '')}
        {required ? '*' : ''}
      </label>
      <Field
        component="textarea"
        name={name}
        id={name}
        className={error && touched ? 'input-class-error' : inputStyle}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export default Textarea;
