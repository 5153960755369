import React, { Component, ReactNode } from 'react';
import './radio.css';

interface Props {
  handleInputChanges: (option: string) => void;
  options: Record<any, any>[];
  name?: string;
}

interface State {
  selectedOption: string;
}

class RadioButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (value: string): void => {
    this.setState({ selectedOption: value });
    this.props.handleInputChanges(value);
  };

  render(): ReactNode {
    return (
      <div className="space-x-3 modern-radio-container">
        {this.props.options.map((option, index) => {
          return (
            <div
              className="inline-flex"
              key={`radio-option-${index}`}
              onClick={() => {
                this.handleChange(option.value);
              }}
            >
              <div
                className={`radio-outer-circle
              ${option.value !== this.state.selectedOption && 'unselected'}`}
              >
                <div
                  className={`radio-inner-circle
                ${option.value !== this.state.selectedOption && 'unselected-circle'}`}
                />
              </div>
              <div className="helper-text">{option.label}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default RadioButton;
