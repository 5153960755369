import React, { Component, ReactNode } from 'react';
import Select, { components, IndicatorProps, PlaceholderProps } from 'react-select';
import { CaretDownIcon, CaretUpIcon } from '../../../images/icons/font-awesome-icons';
import { InputProps } from '../formik/inputs/inputProps';
const Placeholder: React.FC<PlaceholderProps<any, any, any>> = (props) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator: React.FC<IndicatorProps<any, any, any>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {!props.selectProps.menuIsOpen ? <CaretDownIcon /> : <CaretUpIcon />}
    </components.DropdownIndicator>
  );
};

interface Props extends InputProps {
  handleInputChanges: (option: string) => void;
  placeholder?: string;
}

interface State {
  selectedOption: string;
}

class SelectInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: '',
    };
  }

  handleChange: React.ChangeEventHandler<HTMLSelectElement> = (selectedOption: any) => {
    const value = selectedOption.target ? selectedOption.target.value : selectedOption.value;
    const currentState = this.state;
    this.setState({ selectedOption: value });
    this.props.handleInputChanges(currentState.selectedOption);
  };

  getDefaultValue = (type = 'value'): any => {
    if (!this.state.selectedOption) {
      return '';
    }
    const selectedValue = this.props.options?.find((option) => {
      return option.value === this.state.selectedOption;
    });
    if (type === 'value') {
      return selectedValue?.value;
    }
    return selectedValue?.label;
  };

  render(): ReactNode {
    return (
      <div>
        <div>
          <Select
            inputId={this.props.name}
            closeMenuOnSelect
            components={{
              Placeholder,
              DropdownIndicator,
              'IndicatorSeparator': () => null,
            }}
            placeholder={this.getDefaultValue('label') || this.props.placeholder}
            value={this.getDefaultValue('value')}
            options={this.props.options}
            styles={{
              'placeholder': (base) => ({
                ...base,
                'color': 'black',
                'fontSize': '18px',
                'lineHeight': '46px',
                'letterSpacing': '0.02em',
                'fontWeight': 'lighter',
                'fontFamily': 'GoodSansLight',
              }),
              'control': (base) => ({
                ...base,
                'border':
                  this.props.error && this.props.touched
                    ? '2px solid #e53e3e'
                    : '1px solid #707070',
              }),
            }}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default SelectInput;
