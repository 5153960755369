import React, { Component, ReactNode } from 'react';
import { parseToBase64 } from '../../../utils/files';

export interface SelectedOption {
  value: string;
  file?: string | ArrayBuffer;
  rawFile?: File;
}

interface Props {
  name: string;
  error?: string;
  touched?: boolean;
  placeholder?: string;
  buttonLabel?: string;
  acceptedFormats?: string;
  handleInputChanges: (selectedOption: SelectedOption) => void;
}

interface State {
  selectedOption: SelectedOption;
}

class FileInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: { value: '' },
    };
    this.handleFileInputOnClick = this.handleFileInputOnClick.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  handleFileInputOnClick: React.MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    document.getElementById(this.props.name)?.click();
  };

  handleFileSelected: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const currentState = this.state;
    const { target } = e;
    const file = target.files?.length ? target.files[0] : null;

    if (file) {
      currentState.selectedOption.value = file.name;
      currentState.selectedOption.file = await parseToBase64(file);
      currentState.selectedOption.rawFile = file;
      this.setState(currentState);
      this.props.handleInputChanges(currentState.selectedOption);
    }
  };

  render(): ReactNode {
    return (
      <div>
        <div className="grid grid-cols-3 pt-2 laptop-standard:pt-1">
          <div
            className={`col-span-2 format-text-file-input select-none whitespace-nowrap ${
              this.props.error && this.props.touched ? 'border-red-600 border-2' : ''
            } ${this.state.selectedOption.value.length > 0 ? '' : '!text-gray-600'}`}
          >
            {this.state.selectedOption.value.length > 0
              ? this.state.selectedOption.value
              : this.props.placeholder}
          </div>
          <button className="upload-button-file" onClick={this.handleFileInputOnClick}>
            {this.props.buttonLabel}
          </button>
        </div>
        <input
          name={this.props.name}
          id={this.props.name}
          onChange={this.handleFileSelected}
          type="file"
          style={{ 'display': 'none' }}
          accept={this.props.acceptedFormats}
        />
      </div>
    );
  }
}

export default FileInput;
