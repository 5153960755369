import React from 'react';
import { Field, FieldProps } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import CheckboxInput from '../../custom-inputs/checkbox';
import { InputProps } from './inputProps';

const Checkbox: React.FC<InputProps> = (props) => {
  const { required, label } = props;
  return (
    <Field name={props.name} type={props.type}>
      {({ form: { setFieldValue } }: FieldProps<any>) => (
        <div
          className={
            props.error && props.touched
              ? `${props.divStyle} border-red-600 border-2`
              : `${props.divStyle}`
          }
        >
          <label htmlFor={props.name}>
            {ReactHtmlParser(label ?? '')}
            {required ? '*' : ''}
          </label>
          <CheckboxInput
            options={props.options ?? []}
            handleInputChanges={(selectedValue: any) => {
              return setFieldValue(props.name ?? '', selectedValue);
            }}
          />
        </div>
      )}
    </Field>
  );
};

export default Checkbox;
