import { InputProps } from '../components/forms/formik/inputs/inputProps';

const jobPositionTitle = "Ready to Apply?<br/> Let's do this.";
const jobPositionInputs: InputProps[] = [
  {
    'type': 'text',
    'required': true,
    'disabled': true,
    'label': 'Department',
    'name': 'department',
    'divStyle': 'form-input-container space-y-2',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'text',
    'required': true,
    'disabled': true,
    'label': 'Position',
    'name': 'position',
    'divStyle': 'form-input-container space-y-2',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'text',
    'required': true,
    'label': 'First Name',
    'name': 'first_name',
    'divStyle': 'space-y-2 form-input-container',
    'inputStyle': 'form-text-input-style',
    'minLength': '1',
  },
  {
    'type': 'text',
    'required': true,
    'label': 'Last Name',
    'name': 'last_name',
    'divStyle': 'form-input-container space-y-2',
    'inputStyle': 'form-text-input-style',
    'minLength': '2',
  },
  {
    'type': 'email',
    'required': true,
    'label': 'Email Address',
    'name': 'email_address',
    'divStyle': 'form-input-container space-y-2',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'phone',
    'required': true,
    'label': 'Phone Number',
    'name': 'phone_number',
    'divStyle': 'form-input-container space-y-2',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'text',
    'required': true,
    'label': 'Street Address',
    'name': 'street',
    'divStyle': 'space-y-3 col-span-2 pt-6 laptop-standard:pt-0',
    'inputStyle': 'form-text-input-style',
    'minLength': '5',
  },
  {
    'group': true,
    'styleContainer':
      'col-span-2 grid grid-cols-1 laptop-standard:grid-cols-3 items-end mobile:space-y-3 laptop-standard:space-y-0',
    'inputs': [
      {
        'type': 'text',
        'required': false,
        'label': 'City',
        'name': 'city',
        'divStyle': 'form-input-container space-y-3',
        'inputStyle': 'form-text-input-style',
      },
      {
        'type': 'select',
        'required': false,
        'label': 'State',
        'name': 'State',
        'placeholder': 'Select State',
        'divStyle': 'form-input-container space-y-3 gap-inputs-grid',
        'options': [
          { 'value': 'AL', 'label': 'Alabama' },
          { 'value': 'AK', 'label': 'Alaska' },
          { 'value': 'AS', 'label': 'American Samoa' },
          { 'value': 'AZ', 'label': 'Arizona' },
          { 'value': 'AR', 'label': 'Arkansas' },
          { 'value': 'CA', 'label': 'California' },
          { 'value': 'CO', 'label': 'Colorado' },
          { 'value': 'CT', 'label': 'Connecticut' },
          { 'value': 'DE', 'label': 'Delaware' },
          { 'value': 'DC', 'label': 'District Of Columbia' },
          { 'value': 'FM', 'label': 'Federated States Of Micronesia' },
          { 'value': 'FL', 'label': 'Florida' },
          { 'value': 'GA', 'label': 'Georgia' },
          { 'value': 'GU', 'label': 'Guam' },
          { 'value': 'HI', 'label': 'Hawaii' },
          { 'value': 'ID', 'label': 'Idaho' },
          { 'value': 'IL', 'label': 'Illinois' },
          { 'value': 'IN', 'label': 'Indiana' },
          { 'value': 'IA', 'label': 'Iowa' },
          { 'value': 'KS', 'label': 'Kansas' },
          { 'value': 'KY', 'label': 'Kentucky' },
          { 'value': 'LA', 'label': 'Louisiana' },
          { 'value': 'ME', 'label': 'Maine' },
          { 'value': 'MH', 'label': 'Marshall Islands' },
          { 'value': 'MD', 'label': 'Maryland' },
          { 'value': 'MA', 'label': 'Massachusetts' },
          { 'value': 'MI', 'label': 'Michigan' },
          { 'value': 'MN', 'label': 'Minnesota' },
          { 'value': 'MS', 'label': 'Mississippi' },
          { 'value': 'MO', 'label': 'Missouri' },
          { 'value': 'MT', 'label': 'Montana' },
          { 'value': 'NE', 'label': 'Nebraska' },
          { 'value': 'NV', 'label': 'Nevada' },
          { 'value': 'NH', 'label': 'New Hampshire' },
          { 'value': 'NJ', 'label': 'New Jersey' },
          { 'value': 'NM', 'label': 'New Mexico' },
          { 'value': 'NY', 'label': 'New York' },
          { 'value': 'NC', 'label': 'North Carolina' },
          { 'value': 'ND', 'label': 'North Dakota' },
          { 'value': 'MP', 'label': 'Northern Mariana Islands' },
          { 'value': 'OH', 'label': 'Ohio' },
          { 'value': 'OK', 'label': 'Oklahoma' },
          { 'value': 'OR', 'label': 'Oregon' },
          { 'value': 'PW', 'label': 'Palau' },
          { 'value': 'PA', 'label': 'Pennsylvania' },
          { 'value': 'PR', 'label': 'Puerto Rico' },
          { 'value': 'RI', 'label': 'Rhode Island' },
          { 'value': 'SC', 'label': 'South Carolina' },
          { 'value': 'SD', 'label': 'South Dakota' },
          { 'value': 'TN', 'label': 'Tennessee' },
          { 'value': 'TX', 'label': 'Texas' },
          { 'value': 'UT', 'label': 'Utah' },
          { 'value': 'VT', 'label': 'Vermont' },
          { 'value': 'VI', 'label': 'Virgin Islands' },
          { 'value': 'VA', 'label': 'Virginia' },
          { 'value': 'WA', 'label': 'Washington' },
          { 'value': 'WV', 'label': 'West Virginia' },
          { 'value': 'WI', 'label': 'Wisconsin' },
          { 'value': 'WY', 'label': 'Wyoming' },
        ],
      },
      {
        'type': 'number',
        'required': false,
        'label': 'Zip Code',
        'name': 'zip_code',
        'divStyle': 'form-input-container space-y-3',
        'inputStyle': 'form-text-input-style',
      },
    ],
  },
  {
    'type': 'radio',
    'required': false,
    'label': 'Do you have agency experience?',
    'name': 'agency',
    'divStyle': 'form-input-container space-y-3 margin-top-wide',
    'options': [
      { 'label': 'Yes', 'value': 'yes' },
      { 'label': 'No', 'value': 'no' },
    ],
  },
  {
    'type': 'text',
    'required': false,
    'label': 'Who/what brought you to us?',
    'name': 'reference',
    'caption': '(ie. self, job posting website, employee referral name)',
    'divStyle': 'form-input-container space-y-3 margin-top-wide',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'text',
    'required': true,
    'label': 'Portofolio/personal site or Linkedin URLs?',
    'name': 'portofolio',
    'divStyle': 'form-input-container space-y-3',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'file',
    'required': true,
    'label': 'Upload something (.pdf)',
    'fieldId': 'fileUpload',
    'placeholder': 'Choose file',
    'divStyle': 'form-input-container',
    'name': 'fileUpload',
    'buttonLabel': 'Upload',
    'acceptedFormats': 'application/pdf',
  },
  {
    'type': 'radio',
    'required': true,
    'label': 'Are you currently authorized to work for all <br/> employers in the United States?',
    'name': 'work_permission',
    'divStyle': 'form-input-container space-y-3 padding-radio-work-permission',
    'options': [
      { 'label': 'Yes', 'value': 'yes' },
      { 'label': 'No', 'value': 'no' },
    ],
  },
  {
    'type': 'empty',
  },
  {
    'type': 'text',
    'label': 'Screener question 1',
    'name': 'screener1',
    'divStyle': 'form-input-container space-y-3 screener-first-class',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'empty',
  },
  {
    'type': 'text',
    'label': 'Screener question 2',
    'name': 'screener2',
    'divStyle': 'form-input-container space-y-3 screener-second-class',
    'inputStyle': 'form-text-input-style',
  },
  {
    'type': 'empty',
  },
  {
    'type': 'text',
    'label': 'Screener question 3',
    'name': 'screener3',
    'divStyle': 'form-input-container space-y-3',
    'inputStyle': 'form-text-input-style',
  },
];

export { jobPositionTitle, jobPositionInputs };
