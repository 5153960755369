import React from 'react';
import Input from './inputs/input';
import Textarea from './inputs/textarea';
import Select from './inputs/select';
import Radio from './inputs/radio';
import File from './inputs/file';
import Checkbox from './inputs/checkbox';
import { InputProps } from './inputs/inputProps';

interface Props {
  control?: string;
  input: InputProps;
}

const FormikControl: React.FC<Props> = (props) => {
  const { control, input } = props;
  switch (control) {
    case 'input':
      return <Input {...input} />;
    case 'textarea':
      return <Textarea {...input} />;
    case 'select':
      return <Select {...input} />;
    case 'radio':
      return <Radio {...input} />;
    case 'file':
      return <File {...input} />;
    case 'checkbox':
      return <Checkbox {...input} />;
    default:
      return null;
  }
};

export default FormikControl;
