import React from 'react';
import { navigate } from 'gatsby';
import Content from '../components/careers/content';
import SubmissionForm from '../components/forms/submission-form';
import Layout from '../components/layout';
import ValidatorSchema from '../utils/yup-validator';
import { GetAllJobsDataQuery } from '../../graphql-types';
import { InputProps } from '../components/forms/formik/inputs/inputProps';
import { jobPositionTitle, jobPositionInputs } from './job-inputs';

interface Props {
  pageContext: GetAllJobsDataQuery['allJob']['nodes'][0] & { [key: string]: string };
}

const generateSchemaValidator = (inputs: InputProps[]) => {
  const formInputs = inputs.reduce<Array<any>>((list, input) => {
    if (input.group && input.inputs) {
      for (const singleInput of input.inputs) {
        list.push(singleInput);
      }
    } else {
      list.push(input);
    }
    return list;
  }, []);
  const schema = ValidatorSchema.generateCustomSchema(formInputs);
  return schema;
};

const concatenateAddress = (fields: Record<string, any>) => {
  let address = fields.street;
  ['city', 'State', 'zip_code'].forEach((key) => {
    if (fields[key]) {
      address += `, ${fields[key]}`;
    }
  });
  return address;
};

const sendApplication: (values: Record<string, any>, props: any) => void = async (
  fields,
  options
) => {
  const { departmentId, positionId } = options;
  let response = false;
  const authResponse = await fetch(`${process.env.GATSBY_DEUTSCH_HR_API}/auth`)
    .then((responseHR) => {
      if (responseHR.ok) {
        return responseHR.json();
      }

      navigate('/careers/application-error');
      return false;
    })
    .catch(() => {
      navigate('/careers/application-error');
      return false;
    });
  if (authResponse) {
    const baseUrl = process.env.GATSBY_DEUTSCH_HR_API;

    response = await fetch(`${baseUrl}/candidates`, {
      'method': 'post',
      'headers': {
        'Authorization': `Bearer ${authResponse.access_token}`,
        'Content-Type': 'application/json',
      },
      'body': JSON.stringify({
        'department_id': departmentId,
        'position_id': positionId,
        'first_name': fields.first_name,
        'last_name': fields.last_name,
        'email': fields.email_address,
        'phone': fields.phone_number,
        'address1': concatenateAddress(fields),
        'referral': fields.reference ?? '',
        'agency_experience': fields.agency === 'yes' ? true : false,
        'authorized': fields.work_permission === 'yes' ? true : false,
        'portfolio_url': fields.portofolio,
        'screener_answer_1': fields.screener1 ?? '',
        'screener_answer_2': fields.screener2 ?? '',
        'screener_answer_3': fields.screener3 ?? '',
        'resume': fields.fileUpload.file,
      }),
    })
      .then(async (responseHR) => {
        if (responseHR.ok) {
          navigate('/careers/application-success');
          return true;
        }
        const message = await responseHR.json();
        navigate('/careers/application-error', { state: { error: message.error } });
        return false;
      })
      .catch(() => {
        navigate('/careers/application-error');
        return false;
      });
  }
  return response;
};

const setPositionDataToInputs = (position: Props['pageContext'], currentInputs: InputProps[]) => {
  let inputs = currentInputs;
  if (position.department) {
    const departmentInput = currentInputs.find((input) => input.name === 'department');
    if (departmentInput) {
      departmentInput.value = position.department;
    }
  }

  if (position.title) {
    const positionInput = currentInputs.find((input) => input.name === 'position');
    if (positionInput) {
      positionInput.value = position.title;
    }
  }

  for (let index = 1; index <= 3; index++) {
    if (position[`screener_question_${index}`]) {
      const screenerInput = currentInputs.find((input) => input.name === `screener${index}`);
      if (screenerInput) {
        screenerInput.label = position[`screener_question_${index}`];
      }
    } else {
      inputs = inputs.filter((input) => input.name !== `screener${index}`);
    }
  }
  return inputs;
};

const JobPosition: React.FC<Props> = ({ pageContext }) => {
  return (
    <Layout
      seo={{
        'author': 'Deutsch LA',
        'keywords': 'Deutsch LA, deutsch',
        'title': pageContext.title ?? '',
      }}
    >
      <div className="mx-8 laptop-standard:mx-15">
        <Content
          title={pageContext.title ?? ''}
          description={pageContext.description?.body ?? ''}
        />
        <SubmissionForm
          title={jobPositionTitle}
          inputs={setPositionDataToInputs(pageContext, jobPositionInputs)}
          handleSubmit={sendApplication}
          departmentId={pageContext.department_id ?? 0}
          positionId={pageContext.description?.id ?? ''}
          validationSchema={generateSchemaValidator(jobPositionInputs)}
        />
      </div>
    </Layout>
  );
};

export default JobPosition;
