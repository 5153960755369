/* eslint-disable max-len */
import * as yup from 'yup';
import { InputProps } from '../components/forms/formik/inputs/inputProps';

class ValidatorSchema {
  static generateCustomSchema(inputs: InputProps[]): yup.ObjectSchema<any> {
    const schema: Record<string, any> = {};
    for (const input of inputs) {
      if (input.name) {
        if (
          input.type &&
          ['text', 'textarea'].includes(input.type) &&
          input.required &&
          input.minLength
        ) {
          schema[input.name] = yup.string().required().min(parseInt(input.minLength, 10));
        } else if (
          input.type &&
          ['text', 'textarea'].includes(input.type) &&
          input.required &&
          !input.minLength
        ) {
          schema[input.name] = yup.string().required();
        } else if (input.type === 'email' && input.required) {
          schema[input.name] = yup.string().email().required();
        } else if (input.type === 'email' && !input.required) {
          schema[input.name] = yup.string().email();
        } else if (input.type === 'phone' && input.required) {
          schema[input.name] = yup
            .string()
            .trim()
            .matches(
              /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
            )
            .required();
        } else if (input.type === 'phone' && !input.required) {
          schema[input.name] = yup
            .string()
            .trim()
            .matches(
              /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
            );
        } else if (input.type === 'checkbox' && input.required) {
          schema[input.name] = yup.array().of(yup.string()).required();
        } else if (input.type === 'checkbox' && !input.required) {
          schema[input.name] = yup.array().of(yup.string());
        } else if (input.type === 'select' && input.required) {
          schema[input.name] = yup.string().required();
        } else if (input.type === 'select' && !input.required) {
          schema[input.name] = yup.string();
        } else if (input.type === 'radio' && input.required) {
          schema[input.name] = yup.string().required();
        } else if (input.type === 'radio' && !input.required) {
          schema[input.name] = yup.string();
        } else if (input.type === 'file' && input.required) {
          schema[input.name] = yup.object().required();
        } else if (input.type === 'file' && !input.required) {
          schema[input.name] = yup.object();
        }
      }
    }
    return yup.object().shape(schema);
  }
}

export default ValidatorSchema;
