export const parseToBase64 = async (file: File): Promise<string | ArrayBuffer> => {
  return new Promise<string | ArrayBuffer>((resolve) => {
    const reader = new FileReader();
    let result: string | ArrayBuffer = '';

    reader.readAsDataURL(file);
    reader.onload = () => {
      result = reader.result ?? result;
      resolve(result);
    };
  });
};
