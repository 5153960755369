import React from 'react';
import { Field } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import FileInput from '../../custom-inputs/file-input';
import { InputProps } from './inputProps';

const File: React.FC<InputProps> = (props) => {
  const { required, label } = props;
  return (
    <Field name={props.name} type={props.type}>
      {({ form: { setFieldValue } }: any) => (
        <div className={props.divStyle}>
          <label htmlFor={props.name}>
            {ReactHtmlParser(label ?? '')}
            {required ? '*' : ''}
          </label>
          <FileInput
            {...props}
            name={props.name ?? ''}
            handleInputChanges={(selectedValue) => {
              return setFieldValue(props.name, selectedValue);
            }}
          />
        </div>
      )}
    </Field>
  );
};

export default File;
