import React from 'react';
import { Formik, Form } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import Button from '../button';
import FormikControl from './formik/formik-control';
import './_submission-form.css';
import { ObjectSchema } from 'yup';
import { InputProps } from './formik/inputs/inputProps';

interface Props {
  title: string;
  inputs: InputProps[];
  formTitleStyle?: string;
  buttonColor?: string;
  textButtonColor?: string;
  handleSubmit: (values: Record<string, any>, props: Props) => void;
  validationSchema: ObjectSchema<any>;
  departmentId?: number;
  positionId?: string;
}

const SubmissionForm: React.FC<Props> = (props) => {
  const loadInitialValues = (inputs: InputProps[]) => {
    const objectInputs = inputs.reduce<Record<string, string>>((objectList, input) => {
      if (input.group && input.inputs) {
        for (const singleInput of input.inputs) {
          objectList[singleInput.name ?? ''] = singleInput.value ?? '';
        }
      }
      if (input.name) {
        objectList[input.name] = input.value ?? '';
      }
      return objectList;
    }, {});
    return objectInputs;
  };

  // Params: input object, error, touched and index
  // eslint-disable-next-line max-params
  const renderInput = (input: InputProps, error?: string, touched?: boolean, index?: number) => {
    input.error = error;
    input.touched = touched;
    switch (input.type) {
      case 'text':
      case 'number':
      case 'email': {
        return <FormikControl control="input" input={input} key={`input-${index}`} />;
      }
      case 'phone': {
        input.type = 'text';
        return <FormikControl control="input" input={input} key={`phone-${index}`} />;
      }
      case 'textarea':
      case 'select':
      case 'checkbox':
      case 'radio':
      case 'file': {
        return <FormikControl control={input.type} input={input} key={`${input.type}-${index}`} />;
      }
      default:
        return <div className={`${input.divStyle}`} key={`empty-div-${index}`}></div>;
    }
  };
  return (
    <div id="applyForm" className="pt-6 laptop-standard:pt-30 max-width">
      <Formik
        initialValues={loadInitialValues(props.inputs)}
        validationSchema={props.validationSchema}
        onSubmit={(values) => {
          return props.handleSubmit(values, props);
        }}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form className="margin-x-apply-form  text-base laptop-standard:text-md">
              <div className="container-apply-form">
                <div className="form-title-container">
                  <p className={`${props.formTitleStyle ?? 'title-apply-form'}`}>
                    {ReactHtmlParser(props.title)}
                  </p>
                </div>
                {/* Empty div to match XD design */}
                <div></div>
                {/* Render inputs */}
                {props.inputs.map((input, index) => {
                  if (input.group && input.inputs) {
                    return (
                      <div className={input.styleContainer} key={`parent-input-${index}`}>
                        {input.inputs.map((singleInput, indexKey) => {
                          return renderInput(
                            singleInput,
                            errors[singleInput.name ?? ''],
                            touched[singleInput.name ?? ''],
                            indexKey
                          );
                        })}
                      </div>
                    );
                  }
                  return renderInput(
                    input,
                    errors[input.name ?? ''],
                    touched[input.name ?? ''],
                    index
                  );
                })}
              </div>
              <div className="submit-button-container">
                <Button
                  buttonTitle="Submit"
                  contentType="submit"
                  internalLink="#/"
                  disabled={isSubmitting}
                  backgroundColor={props.buttonColor ?? 'bg-orange-700 border-orange-700'}
                  textColor={props.textButtonColor ?? 'text-white'}
                  styles="submit-button-text-format font-light"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SubmissionForm;
