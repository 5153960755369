import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs((props) => {
  return {
    'type': 'checkbox',
    'value': props.value,
  };
})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid black;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px black;
  }
  ${Icon} {
    visibility: ${(props: any) => {
      return props.checked ? 'visible' : 'hidden';
    }};
  }
` as any;

interface Props {
  options: Record<any, any>[];
  handleInputChanges: (value: any[]) => void;
}

interface State {
  options: Record<any, any>;
}

const loadOptions = (props: Props) => {
  const optionsState: State = { options: {} };
  optionsState.options = props.options.reduce((objectList, option) => {
    objectList[option.value] = false;
    return objectList;
  }, {});
  return optionsState;
};

class CheckBoxInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = loadOptions(this.props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange: React.FormEventHandler<HTMLLabelElement> = (e: any) => {
    const currentState = this.state;
    currentState.options[e.target.value] = !currentState.options[e.target.value];
    this.setState(currentState);
    const selectedValues = Object.keys(this.state.options).reduce<Array<any>>((list, key) => {
      if (this.state.options[key]) {
        list.push(key);
      }
      return list;
    }, []);
    this.props.handleInputChanges(selectedValues);
  };

  render(): ReactNode {
    return (
      <div className="grid">
        {this.props.options.map((option, index) => {
          return (
            <label
              key={`input-check-${index}`}
              className="items-center inline-flex"
              onChange={this.handleChange}
            >
              <HiddenCheckbox value={option.value} />
              <StyledCheckbox
                checked={
                  typeof this.state.options[option.value] === 'boolean'
                    ? this.state.options[option.value]
                    : false
                }
              >
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </StyledCheckbox>
              <span className="pl-4 text-base leading-10 tracking-desk">{option.label}</span>
            </label>
          );
        })}
      </div>
    );
  }
}

export default CheckBoxInput;
