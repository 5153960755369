import React from 'react';
import { Field, FieldProps } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import SelectInput from '../../custom-inputs/select-input';
import { InputProps } from './inputProps';

const Select: React.FC<InputProps> = (props) => {
  const { required, label } = props;
  return (
    <Field name={props.name} type={props.type}>
      {({ form: { setFieldValue } }: FieldProps<any>) => (
        <div className={props.divStyle}>
          <label htmlFor={props.name}>
            {ReactHtmlParser(label ?? '')}
            {required ? '*' : ''}
          </label>
          <SelectInput
            {...props}
            handleInputChanges={(selectedValue: any) => {
              return setFieldValue(props.name ?? '', selectedValue);
            }}
          />
        </div>
      )}
    </Field>
  );
};

export default Select;
