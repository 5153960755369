import { Link } from 'gatsby';
import React from 'react';
import Button from '../../components/button';
import './content.css';

interface Props {
  title: string;
  description: string;
}

const CareerContent: React.FC<Props> = ({ title, description }) => {
  return (
    <div className="max-width border-t-2 border-b-2 border-gray-300 pb-16 laptop-standard:pb-24">
      <div className="text-center font-semibold pt-14 pb-10 laptop-standard:pt-25 laptop-standard:pb-14">
        <Link to="/careers">
          <p>Careers</p>
        </Link>
      </div>
      <div className="laptop-standard:mx-12">
        <h2 className="jobs-position-title">{title}</h2>
        <div className="h-6 laptop-standard:h-13" />
        <div className="flex place-content-center pb-8">
          <Button buttonTitle="Apply" contentType="careers" />
        </div>
        <div className="h-6 laptop-standard:h-13" />
      </div>
      <div className="tablet:mx-16 laptop-standard:mx-0 desktop-standard:mx-24">
        <div
          className="career-content body1 laptop-standard:mx-40 desktop-large:mx-64"
          dangerouslySetInnerHTML={{ '__html': description }}
        />
      </div>
    </div>
  );
};

export default CareerContent;
